import request from 'browser-request';
import Vue from 'vue';
import ChatWithSales from 'vue-components/chat-bubbles/ChatWithSalesFromGetHelpPill';
import { preloadData } from 'helpers/preloaded';

const els = {
	// populate in wait
	animationContainer: null,
	intercomOpen: null,
	intercomOpenFromPill: null,
	supportShell: null,
	supportShellButton: null,
	supportShellHeader: null
};

const wait = () => {
	if (document.querySelector('.support-shell-trigger') || document.querySelector('.intercom-trigger')) {
		els.animationContainer = document.querySelector('.support-shell-container');
		els.intercomOpen = document.querySelector('.chat-trigger');
		els.intercomOpenFromPill = document.querySelector('.intercom-trigger');
		els.supportShellHeader = document.querySelector('.support-shell__header');
		els.supportShellButton = document.querySelector('.support-shell-trigger');
		els.additionalTriggers = document.querySelectorAll('.js-remote-support-shell-trigger');

		new Vue(ChatWithSales).$mount('#open-drift');

		eventListen();
	} else {
		setTimeout(wait, 250);
	}
};

const eventListen = () => {
	if (els.supportShellButton) {
		els.supportShellButton.addEventListener('click', openShell);
		window.addEventListener('supportShell:open', openShell);
	}

	els.supportShellHeader.addEventListener('click', closeShell);
	els.additionalTriggers.forEach((trigger) => {
		trigger.addEventListener('click', openShell);
	});

	window.addEventListener('modal:open', () => {
		els.animationContainer.classList.add('hidden');
	});

	window.addEventListener('modal:close', () => {
		els.animationContainer.classList.remove('hidden');
	});

	if (window.Intercom) {
		window.Intercom('onHide', () => {
			fetch('/intercom_poll')
				.then((response) => response.json())
				.then((data) => {
					if (data.active) {
						els.supportShellButton.removeEventListener('click', openShell);
						els.supportShellButton.addEventListener('click', showIntercom);
					} else {
						els.supportShellButton.removeEventListener('click', showIntercom);
						els.supportShellButton.addEventListener('click', openShell);
					}
				});
		});
	}

	if (els.intercomOpenFromPill) {
		// does not exist if no chat is active
		els.intercomOpenFromPill.addEventListener('click', openIntercom);
	}

	if (els.intercomOpen) {
		// does not exist if no agents are online
		els.intercomOpen.addEventListener('click', openIntercomChat);
		els.intercomOpen.addEventListener('click', closeShell);
	}
};

const openShell = (e) => {
	e.preventDefault();
	els.animationContainer.classList.add('open');
	loadTickets();
};

const closeShell = () => {
	els.animationContainer.classList.remove('open');
};

const showIntercom = () => {
	window.Intercom('show');
};

const openIntercom = () => {
	window.Intercom('showMessages');
};

const openIntercomChat = () => {
	window.Intercom('showNewMessage');
};

const ticketComponent = {
	created() {
		this.fetchTickets();
	},
	data() {
		return {
			tickets: [],
			count: null
		};
	},

	methods: {
		fetchTickets() {
			request(
				{
					url: '/tickets',
					json: true
				},
				(error, response, body) => {
					if (!error) {
						this.tickets = body.open_tickets.tickets;
						this.count = body.open_tickets.count;
					}
				}
			);
		}
	}
};

const loadTickets = () => {
	new Vue(ticketComponent).$mount('#open-tickets');
};

export default {
	init() {
		wait();
	}
};
